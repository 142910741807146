import React from 'react'
import Circle from './circle'
import './solarium.css'
import mediaContext from '../../../components/context/media-context'

export default function Solar() {
    // turn motion on/off
    const [motion, setMotion] = React.useState(true)
    const media = React.useContext(mediaContext)
    const circleSizes = media.isMobileOrTablet || media.isPortrait ?
        [250 , 175, 88] :
        [300, 200, 100]
    function changeMotion(bool: boolean) {
        setMotion(bool)
    }

    // true = pause, false = play
    const playOrPauseIcon = motion ? "https://cdn-icons-png.flaticon.com/128/2920/2920686.png":"https://cdn-icons-png.flaticon.com/128/5577/5577228.png"
    return (<div className="solarium">
        <Circle 
            size={circleSizes[0]} 
            zLayer={1} 
            time={8000} 
            motion={motion}
            link="https://react.dev/"
            icon="https://cdn-icons-png.flaticon.com/128/753/753244.png"
        />
        <Circle 
            size={circleSizes[1]} 
            zLayer={2} 
            time={6000} 
            motion={motion}
            link="https://www.typescriptlang.org/"
            icon="https://cdn-icons-png.flaticon.com/128/5968/5968381.png"
        />
        <Circle 
            size={circleSizes[2]} 
            zLayer={3} 
            time={4000} 
            motion={motion}
            link="https://www.rust-lang.org/"
            icon="https://miqh.gallerycdn.vsassets.io/extensions/miqh/vscode-language-rust/0.14.0/1536151476041/Microsoft.VisualStudio.Services.Icons.Default"
        />
        <div className="motion icon-holder" onClick={()=>changeMotion(!motion)}>
            <img src={playOrPauseIcon} draggable={false}/>
        </div>
    </div>)
}