import React from "react"
import "./circle-list.css"

interface SkillListItem {
    name: string,
    icon: string
}

interface CircleListProps {
    name: string,
    list: SkillListItem[],
    selection: Set<string>,
    changeSelection: Function,
}

function CircleList(props:CircleListProps){

    const listElements = props.list.map((element)=>{
        let classes = "circle-list-item"
        //project type guard
        if (props.changeSelection && props.selection && props.selection.has(element.name)) {
            classes += " circle-list-item-selected"
        }
        return <div className={classes} key={element.name}>
            <img src={element.icon} alt="" onClick={()=>props.changeSelection(element.name)}/>
            <h4>{element.name}</h4>
        </div>
    })

    return(<div className="circle-list">
        <h2>{props.name}</h2>
        <div className="circle-list-section">
            {listElements}
        </div>
    </div>)
}

export {CircleList as default, type SkillListItem}