import React from 'react'
import {useSpring, animated} from '@react-spring/web'

interface CircleProps {
    size: number,
    zLayer: number,
    time: number,
    motion: boolean,
    link: string,
    icon: string
}

const circleStyle = (size: number, zLayer: number) => { return {
    width: `${size}px`,
    height: `${size}px`,
}}

export default function Circle(props: CircleProps){
    const circleSpring = useSpring({
        loop: true,
        from: {transform: `rotate(0deg) translateX(${props.size/2}px) rotate(0deg)`},
        to: {transform: `rotate(360deg) translateX(${props.size/2}px) rotate(-360deg)`},
        config: {duration: props.time},
        pause: !props.motion
    })
    const currentStyle = circleStyle(props.size, props.zLayer)

    return(<div className="circle" style={currentStyle}>
        <animated.div className="circle-element icon-holder" style={circleSpring}>
            <a href={props.link}><img src={props.icon} alt="icon" draggable={false}/></a>
        </animated.div>
    </div>)
}