import React from 'react'
import "./pageside.css"

interface PagesideProps {
    pageName: string
}

export default function Pageside(props:PagesideProps) {
    return (<div className="pageside">
        <h3>{props.pageName}</h3>
        <div></div>
    </div>)
}