import React from 'react'
import './navbar.css'
import { NavLink, useLocation} from 'react-router-dom'
import mediaContext from '../context/media-context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'

const navbar = <div className="navbar">
                        <div className="navbar-section">
                            <NavLink to="/">
                                <h3>Home</h3>
                            </NavLink>
                            <NavLink to="/projects">
                                <h3>Projects</h3>  
                            </NavLink>
                        </div>
                        <div className="navbar-section">
                            <NavLink to="https://www.linkedin.com/in/nickf-11a846189">
                                <img src="https://cdn-icons-png.flaticon.com/128/3536/3536505.png" alt="Linkedin"/>
                            </NavLink>
                            <NavLink to="https://github.com/Fuadain">
                                <img src="https://cdn-icons-png.flaticon.com/128/5968/5968866.png" alt="Linkedin"/>
                            </NavLink>
                        </div>
                    </div>

export default function Navbar() {
    const currentRoute = useLocation().pathname
    const [toggleNav, setToggleNav] = React.useState(false)

    const media = React.useContext(mediaContext)
    
    function changeToggleNav(toggle: boolean) {
        setToggleNav(toggle)
    }
    React.useEffect(()=>{
        if (!(media.isMobileOrTablet||media.isPortrait)){
            changeToggleNav(false)
        }
    },[media])
    

    return ((media.isMobileOrTablet||media.isPortrait)?
        <>
        {toggleNav?<div className="navbar-under" onClick={()=>changeToggleNav(false)}>
                    
        </div>:""}
        <div className="mobile-navbar">
            {toggleNav?
                navbar
            :
            <div className="navbar-toggle" onClick={()=>changeToggleNav(true)}>
                <FontAwesomeIcon icon={faBars} />
            </div>
            }
            
        </div>
        </>:
        <div className="desktop-navbar">
            {navbar}
        </div>)
}