import React from 'react'
import {useSpring, useSpringRef, useChain, animated, useTransition} from '@react-spring/web'
import CircleList, {SkillListItem} from './circle-list/circle-list'
import ProjectList, {ProjectListItem} from './project-list/project-list'
import Pageside from '../../components/pageside/pageside'
import ProjectPanel from './project-panel/project-panel'
import './projects.css'
import axios from 'axios'
import modeContext from '../../components/context/mode-context'
import mediaContext from '../../components/context/media-context'
import loadingIcon from '../../images/Wedges-3s-200px.svg'

interface ListResponse {
    skills: SkillListItem[],
    projects: ProjectListItem[]
}

export default function Work() {
    const apiLink = React.useContext(modeContext)
    const media = React.useContext(mediaContext)
    const moveTo = media.isMobileOrTablet || media.isPortrait ? "-60%":"-22%"
    const [loading, setLoading] = React.useState(true)
    // for skill selection
    const [selection, setSelection] = React.useState(new Set<string>())
    const [project, setProject] = React.useState<ProjectListItem|null>(null)
    const [skillList, setSkillList] = React.useState<SkillListItem[]>([])
    const [projectList, setProjectList] = React.useState<ProjectListItem[]>([])

    const workRef = useSpringRef()
    const workSpring = useSpring({
        ref: workRef,
        from:{x: "0%"}
    })

    React.useEffect(()=>{
        axios.get<ListResponse>(`${apiLink}/api/projects`)
        .then(res=>{
            setSkillList(res.data.skills)
            setProjectList(prev=>{
                const newList = res.data.projects.map(item=>{
                    return {
                        name: item.name,
                        tags: new Set(item.tags),
                        icon: item.icon
                    }
                })
                return newList
            })
            setLoading(false)
        })
        .catch(e=>{
            console.log(e)
        })
    },[])

    React.useEffect(()=>{
        if(project){
            workRef.start({
                to: {
                    // <number>% in spring works as page percent from original position
                    x: moveTo
                }
            })
        }
    },[media])

    function changeSelection (selected: string) {
        setSelection(prev=>{
            let newSelection = new Set<string>([...prev])
            if (newSelection.has(selected)) {
                newSelection.delete(selected)
            } else {
                newSelection.add(selected)
            }
            return newSelection
        })
    }

    function changeProject(newProject: ProjectListItem) {
        //(project changed from falsey to truey)
        //if projust panel is being pulled up move left

        //(project changed from truey to falsey)
        //if panel is being closed move right

        //(project changed from truey to truey)
        //animation never triggers if project is just being swapped for another
        if(!(project && newProject)){
            workRef.start({
                to: {
                    // <number>% in spring works as page percent from original position
                    x: !project? moveTo : "0%"
                }
            })
        }
        
        setProject(newProject)
    }

    // access backend api for skills and projects
    // github for project info?
    // click on skill to see which projects used them
    // adding border to them
    return (<><animated.div className="page" style={workSpring}>
        <Pageside pageName={"PROJECTS"}/>
        {loading?<img src={loadingIcon} alt="Loading..." className="loading"/>:
        <><CircleList 
            name={"Skills"} 
            list={skillList}
            selection={selection}
            changeSelection={changeSelection}
        />
        <div className="work-section">
            <ProjectList 
                list={projectList}
                selection={selection}
                changeProject={changeProject}
            />
        </div></>}
    </animated.div>
    {
        project?
        <ProjectPanel 
            project={project} 
            changeProject={changeProject}
        />
        :""
    }
    </>)
}