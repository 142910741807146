import React from 'react'
import './project-list.css'

interface ProjectListItem {
    name: string,
    tags: Set<string>,
    icon: string | null
}

interface ProjectListProps {
    list: Array<ProjectListItem>,
    selection: Set<string>,
    changeProject: Function
}

function ProjectList(props: ProjectListProps){
    
    const list = props.list.map(element=>{
        let classes = "project-list-item"
        if(props.selection.size > 0 && [...props.selection].every(tag=>element.tags.has(tag))) {
            classes += " project-list-item-selected"
        }
        return <div className={classes} key={element.name} onClick={()=>props.changeProject(element)}>
            <img src={element.icon?element.icon:""} alt="item"/>
            <h4>{element.name}</h4>
        </div>
    })
    
    return (<div className="project-list">
        <h2>Projects</h2>
        <div className="project-list-section">
            {list}
        </div>
    </div>)
}

export {ProjectList as default, type ProjectListItem}