import { createContext } from "react"

interface mediaType {
    isMobileOrTablet: boolean,
    isPortrait: boolean
}

const mediaContext = createContext<mediaType>({
    isMobileOrTablet: false,
    isPortrait: false
})

export {mediaContext as default, type mediaType}