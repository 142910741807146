import React from 'react'
import Solarium from './solarium/solarium'
import Pageside from '../../components/pageside/pageside'
import './home.css'
import profile from '../../images/profile.jpeg'

export default function Home() {
    return (<div className="page">
        <Pageside pageName={"HOME"}/>
        
        <div className="home-paragraph">
            <div>
                <p>Hello and welcome</p>
                <p>I am Nicholaus Fleming</p>
                <p>I'm a programmer</p>
                <p>Not an alchemist</p>
                <p>and not the immortal kind</p>
                <p>I get it a lot</p>
            </div>
            <img src={profile} alt="profile"/>
        </div>
        <Solarium/>
    </div>)
}