import {useState, createContext} from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';
import './App.css';
import modeContext from './components/context/mode-context';
import mediaContext, {mediaType} from './components/context/media-context';
import Home from './pages/home/home'
import Projects from './pages/projects/projects'
import Navbar from './components/navbar/navbar'
import NoRoute from './pages/no-route/no-route'
import Background from './components/background/background';

const mode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'?"http://localhost:8000":"https://fuadain.onrender.com/"

export default function App() {
  const isMobileOrTablet = useMediaQuery({query:'(max-width: 980px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const media: mediaType = {
    isMobileOrTablet: isMobileOrTablet,
    isPortrait: isPortrait
  }
  return (
    <mediaContext.Provider value={media}>
    <modeContext.Provider value={mode}>
      <Router>
        <Navbar/>
        <Background/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/projects" element={<Projects/>}/>
          <Route path="*" element={<NoRoute/>}/>
        </Routes>
      </Router>
    </modeContext.Provider>
    </mediaContext.Provider>
  )
}