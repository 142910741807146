import React from 'react'
import './project-panel.css'
import {animated, useSpring} from '@react-spring/web'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleXmark} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import loadingIcon from '../../../images/Wedges-3s-200px.svg'
import modeContext from '../../../components/context/mode-context'
import { ProjectListItem } from '../project-list/project-list'
import mediaContext from '../../../components/context/media-context'

interface ProjectPanelProps {
    project: ProjectListItem,
    changeProject: Function
}

interface ProjectDataObject {
    img: string,
    desc: string,
    tags: string[],
    repo: string | null,
    link: string | null
}

export default function ProjectPanel(props: ProjectPanelProps) {
    const apiLink = React.useContext(modeContext)
    const projectSpring = useSpring({
        from: {opacity: 0},
        to: {opacity: 1},
        delay: 300,
    })
    const [projectData, setProjectData] = React.useState<ProjectDataObject|null>(null)
    React.useEffect(()=>{
        axios.get<ProjectDataObject>(`${apiLink}/api/project-info/${props.project.name}`)
        .then(res=>{setProjectData(res.data)})
        .catch(e=>{
            console.log(e)
        })
    },[props])

    let tags: JSX.Element[] = []
    props.project.tags.forEach(tag=>
        tags.push(<div>{tag}</div>)
    )
    console.log(projectData)
    return <animated.div className="project-panel" style={projectSpring}>
        <FontAwesomeIcon 
            icon={faCircleXmark} 
            className="project-panel-exit" 
            onClick={()=>props.changeProject("")}
        />{projectData?
        <>
            <h2>{props.project.name}</h2>
            <img className="project-panel-img" src={projectData.img} alt="Project Image"/>
            <div className="project-panel-desc">
                {projectData.desc}
            </div>
            <h3>Tags</h3>
            <div className="project-panel-tags">
                {tags}
            </div>
            {projectData.repo?
            <div className="project-panel-repo">
                <h3>Repo</h3>
                <a href={projectData.repo}>{projectData.repo}</a>
            </div>:""}
            {projectData.link?
            <div className="project-panel-link">
                <h3>Link</h3>
                <a href={projectData.link}>{projectData.link}</a>
            </div>:""}
        </>:
        <img src={loadingIcon} className="loading"/>}
    </animated.div>
}